<template>
    <div class="box">
        <div style="height:15px"></div>
        <div class="btns">
            <a-button :type="$route.name=='CargoNum'?'primary':''">
                <router-link to="/DataStatistics/CargoNum">货量统计</router-link>
            </a-button>
            <a-button :type="$route.name=='FreightStatistics'?'primary':''">
                <router-link to="/DataStatistics/FreightStatistics">运费统计</router-link></a-button>
            <a-button :type="$route.name=='Limitation'?'primary':''">
                <router-link to="/DataStatistics/Limitation">时效统计</router-link></a-button>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "DataStatistics",
        data() {
            return {}
        },
        created() {
            console.log(this.$route);
        }
    }
</script>

<style lang="less" scoped>
    .box {
        background: #f0f2f5;
        // padding: 15px;

        .btns {
            width: 300px;
            display: flex;
            justify-content: space-between;
        }

    }
</style>